import {useQuery, useLazyQuery, gql} from "@apollo/client";
import { wrapClick } from "utils";
import { Modal } from "components/layouts";
import { useRef} from "react";
import {useReactToPrint} from "react-to-print";
import toast from "react-hot-toast";
import { InvoiceView } from "components/cards";


export const GET_INVOICE = gql`
  query GetInvoice($id: ID!) {
    invoice: getInvoice(id: $id) {
      _id
      code
      description
      type
      invoiceTo {
        fullName
        accountNumber
        phoneNumber
      }
      invoiceItem {
        ...on ServiceRequestSub {
          code
        }
        ...on ServiceRequest{
            code
        }
        ...on Customer {
            code  
        }
      }
      invoiceItemType
      items {
        title
        unitCost
        quantity
        cost
      }
      amount
      status
      paidAt
      createdAt
      updatedAt
    }
  }
`;

export const CHECK_INVOICE = gql`
  query CheckInvoiceStatus($id: ID!) {
    invoice: checkInvoiceStatus(id: $id) {
      _id
      status
    }
  }
`


export default function ViewInvoiceContainer({
  invoiceId,
  open,
  setOpen,
  refetch: refetchParent
}: {
  invoiceId: string;
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch: () => void;
}) {
  const { data, loading, refetch } = useQuery(GET_INVOICE, {
    variables: {
      id: invoiceId,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });

  const [checkInvoiceStatus, { loading: checkInvoiceStatusLoading }] = useLazyQuery(CHECK_INVOICE, {
    onCompleted(data) {
      if(data?.invoice?.status === "Paid") {
        toast(JSON.stringify({ type: "success", title: "Invoice Status Updated Successfully" }));
      }
      else {
        toast(JSON.stringify({ type: "error", title: "Invoice Not Paid For"}))
      }
      refetch?.();
      refetchParent?.();
    },
    variables: {
      id: data?.invoice?._id
    },
    fetchPolicy: "network-only"
  });

  const printRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: data?.invoice?.code,
    bodyClass: "w-[1000px]",
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      hidePadding
      size="4xl"
      title="Invoice Information"
      description="Details of the invoice are shown below"
      renderActions={() => (
        <>
          {data?.invoice?.status === "Pending" && (
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(checkInvoiceStatus)}
            >
              {checkInvoiceStatusLoading ? "Checking Status..." : "Check Invoice Status"}
            </button>
          )}
          <button
            type="button"
            onClick={wrapClick(handlePrint)}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Print
          </button>
        </>
      )}
    >
      {data?.invoice?._id && (
        <>
          <div className="flex-1 w-full min-h-[65vh] max-h-[65vh] overflow-y-auto  sm:p-6">
            <InvoiceView invoice={data?.invoice} printRef={printRef} />
          </div>
        </>
      )}
    </Modal>
  );
}
