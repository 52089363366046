import {EnvelopeIcon, PhoneIcon, GlobeAltIcon} from "@heroicons/react/20/solid";
import moment from "moment/moment";
import numeral from "numeral";
import { InvoiceType, InvoiceItemType } from "../../../constants";
import lodash from "lodash";
import {MutableRefObject} from "react";

interface InvoiceViewProps {
  invoice: {
    code: string;
    batchCode: string;
    externalInvoiceCode: string;
    type: InvoiceType;
    invoiceTo: {
      fullName: string;
      phoneNumber: string;
      accountNumber: string;
    };
    invoiceItem: any;
    invoiceItemType: InvoiceItemType;
    items: {
      title: string;
      unitCost: number;
      quantity: number;
      cost: number;
    }[]
    description: string;
    amount: number;
    year: string;
    region: string,
    district: string,
    status: "Paid" | "Pending" | "Failed";
    isWaived: boolean;
    paidAt: Date;
    createdAt: Date;
    updatedAt: Date;
  },
  printRef: MutableRefObject<any>,
}


export const InvoiceView = ({ invoice, printRef }: InvoiceViewProps) => {

  return (
    <div ref={printRef} className="flex-1 flex flex-col overflow-hidden">
      <div className='hidden print:flex flex-row items-center space-x-4 justify-center py-6 border-b border-gray-300'>
        <div>
          <img
            alt="ECG Logo"
            src={"/logo.png"}
            className="h-32 w-32"
          />
        </div>
        <div className='space-y-1 text-gray-900'>
          <h1 className='text-xl text-primary-600 font-bold'>ELECTRICITY COMPANY OF GHANA LIMITED</h1>
          <div className='flex items-center space-x-6'>
            <div className='flex items-center space-x-2'>
              <EnvelopeIcon className='h-4 w-4'/>
              <span>P.O BOX GP 521, ACCRA</span>
            </div>
            <div className='flex items-center space-x-2'>
              <PhoneIcon className='h-4 w-4'/>
              <span>0302-611-611</span>
            </div>
            <div className='flex items-center space-x-2'>
              <GlobeAltIcon className='h-4 w-4'/>
              <span>ecg@ecggh.com</span>
            </div>
          </div>
          <div className='flex items-center space-x-6'>
            <div className='flex items-center space-x-2'>
              <span className='font-semibold'>VAT REG:</span>
              <span>714V000395</span>
            </div>
            <div className='flex items-center space-x-2'>
              <span className='font-semibold'>Website:</span>
              <span>https://www.ecggh.com/</span>
            </div>
          </div>
        </div>
      </div>
      <div className="space-y-6 divide-y divide-gray-200 p-6 overflow-y-auto flex-1">
        <div className='p-4'>
          <div className="flex justify-between items-center cursor-pointer">
            <div>
              <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100"> {lodash.startCase(invoice.type)} Information</h3>
              <p className="mt-1 text-xs text-gray-500">Details about {lodash.lowerCase(invoice.type)}</p>
            </div>
            <div/>
          </div>
          <div className="mt-6">
            <div
              className="px-4 py-4 shadow-sm ring-1 ring-gray-900/5 sm:rounded-lg sm:px-4 sm:pb-6 xl:px-8 xl:pb-10 xl:pt-8 relative">
              <h2 className="text-base font-semibold leading-6 text-gray-900">Invoice
                #{invoice?.code || "N/A"}</h2>
              {invoice?.status === "Paid" && (
                <img
                  alt="ECG Logo"
                  src={"/paid-stamp.png"}
                  className="h-32 w-32 absolute top-8 right-8"
                />
              )}
              <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                <div className="sm:pr-4">
                  <div>
                    <dt className="inline text-gray-500">Issued on</dt>
                    {' '}
                    <dd className="inline text-gray-700">
                      <time
                        dateTime="2023-23-01">{invoice?.createdAt ? moment(invoice?.createdAt).format("MMMM DD, YYYY") : "N/A"}</time>
                    </dd>
                  </div>
                  {invoice?.status === "Paid" ? (
                    <div className="mt-2 sm:mt-0 sm:pl-4">
                      <dt className="inline text-gray-500">Paid on</dt>
                      {' '}
                      <dd className="inline text-gray-700">
                        <time
                          dateTime="2023-31-01">{invoice?.paidAt ? moment(invoice?.paidAt).format("MMMM DD, YYYY") : "N/A"}</time>
                      </dd>
                    </div>
                  ) : null}
                </div>
                <div className="sm:pr-4">
                  <dt className="inline text-gray-500">{lodash.startCase(invoice?.invoiceItemType)}</dt>
                  {' '}
                  <dd className="inline text-gray-700">
                    {invoice?.invoiceItem?.code ? `#${invoice?.invoiceItem?.code}` : "N/A"}
                  </dd>
                </div>

                <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                  <dt className="font-semibold text-gray-900">From</dt>
                  <dd className="mt-2 text-gray-500">
                    <span className="font-medium text-gray-900">Electricity Company of Ghana Limited</span>
                    <br/>
                    P.O BOX GP 521, ACCRA
                    <br/>
                    ecg@ecggh.com
                  </dd>
                </div>
                <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                  <dt className="font-semibold text-gray-900">To</dt>
                  <dd className="mt-2 text-gray-500">
                    <span className="font-medium text-gray-900">{invoice?.invoiceTo?.fullName}</span>
                    <br/>
                    {invoice?.invoiceTo?.phoneNumber}
                    <br/>
                    Account Number: {invoice?.invoiceTo?.accountNumber}
                  </dd>
                </div>
                <div className="mt-8 sm:pr-4 col-span-2">
                  <span className="font-semibold text-gray-900">Description:</span>
                  <br/>
                  <dd className="inline text-gray-700">
                    {invoice?.description || "N/A"}
                  </dd>
                </div>
              </dl>
              <table
                className="mt-16 pt-16 w-full whitespace-nowrap text-left text-sm leading-6 border-t border-gray-900/5">
                <colgroup>
                  <col className="w-full"/>
                  <col/>
                </colgroup>
                <thead className="border-b border-gray-200 text-gray-900">
                <tr>
                  <th scope="col" className="px-0 py-3 font-semibold">
                    Item
                  </th>
                  <th scope="col" className="px-0 py-3 pl-8 text-right font-semibold">
                    Unit Price
                  </th>
                  <th scope="col" className="px-0 py-3 pl-8 text-right font-semibold">
                    Quantity
                  </th>
                  <th scope="col" className="px-0 py-3 pl-8 text-right font-semibold">
                    Price
                  </th>
                </tr>
                </thead>
                <tbody>
                {invoice?.items?.map((item: any, key: number) => (
                  <tr key={key} className="border-b border-gray-100">
                    <td className="max-w-0 px-0 py-5 align-top">
                      <div className="truncate font-medium text-gray-900">
                        {item?.title || "N/A"}
                      </div>
                    </td>
                    <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                      GH₵ {numeral(item?.unitCost).format("0,0.00")}
                    </td>
                    <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                      {numeral(item?.quantity).format("0,0")}
                    </td>
                    <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                      GH₵ {numeral(item?.cost).format("0,0.00")}
                    </td>
                  </tr>
                ))}
                </tbody>
                <tfoot>
                <tr>
                  <th
                    scope="row"
                    colSpan={3}
                    className="px-0 pb-0 pt-6 text-right font-normal text-gray-700"
                  >
                    Subtotal
                  </th>
                  <td className="pb-0 pl-8 pr-0 pt-6 text-right tabular-nums text-gray-900">
                    GH₵ {numeral(invoice?.amount).format("0,0.00")}
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    colSpan={3}
                    className="pt-4 text-right font-semibold text-gray-900"
                  >
                    Total
                  </th>
                  <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
                    GH₵ {numeral(invoice?.amount).format("0,0.00")}
                  </td>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoiceView;