import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Switch } from "@headlessui/react";
import { classNames, validators, wrapClick } from "utils";
import { AvatarUpload, SelectInput, TextInput } from "../..";
import { FormikProps, useFormik } from "formik";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { CustomerInfoFormSchema, ICustomerInfoFormSchema } from "./schema";
import { Nationalities, Titles, Genders } from "apollo/data";
import _ from "lodash";
import toast from "react-hot-toast";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

export interface CustomerInfoFormProps {
  handleNext: (values: ICustomerInfoFormSchema) => void;
  handlePrevious: () => void;
  initialValues: ICustomerInfoFormSchema;
  handleCancel: () => void;
  parentForm: FormikProps<any>
}

const RESOLVE_GHANA_CARD_INFO = gql`
  query ResolveGhanaCardDetails($ghanaCardNumber: String!) {
    ghanaCard: resolveGhanaCardDetails(ghanaCardNumber: $ghanaCardNumber) {
      _id
      fullName
      dateOfBirth
      gender
      issueDate
      expiryDate
    }
  }
`;


const SEND_VERIFY_CONTACT_CODE = gql`
    mutation SendVerifyContactCode($phoneNumber: String!) {
        sendVerifyContactCode(phoneNumber: $phoneNumber)
    }
`;
const VERIFY_CONTACT_CODE = gql`
    mutation VerifyContactCode($phoneNumber: String!, $passcode: String!) {
        verifyContactCode(phoneNumber: $phoneNumber, passcode: $passcode)
    }
`;


export const CustomerInfoForm: FC<CustomerInfoFormProps> = ({
  initialValues,
  handleNext,
  handleCancel,
  handlePrevious,
  parentForm,
}) => {
  const form = useFormik<ICustomerInfoFormSchema>({
    initialValues,
    validationSchema: CustomerInfoFormSchema,
    onSubmit: (values) => {
      const customerFormHasPhoneNumberError = _.get(form.errors, "representative.phoneNumber")
      const verificationFormPhoneNumber = _.get(verifyContactForm.values, "verifiedPhoneNumber")
      const customerFormPhoneNumber = _.get(form.values, "representative.phoneNumber")
      const parentFormCustomerRepresentativePhoneNumber = parentForm.values.customerInfo.representative.phoneNumber

      if (
        (
          !customerFormHasPhoneNumberError &&
          (verificationFormPhoneNumber !== customerFormPhoneNumber)
          // fixes bug that occurs after user submits, and then hit's the previous button
        ) && (parentFormCustomerRepresentativePhoneNumber !== customerFormPhoneNumber)
      ) {
        form.setFieldError(
          "representative.phoneNumber",
          "Kindly verify phone number"
        );
        return;
      }
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });


  const [sendVerifyContactCode, { loading: sendVerifyContactLoading }] =
  useMutation(SEND_VERIFY_CONTACT_CODE);
  const [verifyContactCode, { loading: verifyContactLoading }] =
  useMutation(VERIFY_CONTACT_CODE);

const verifyContactForm = useFormik<any>({
  initialValues: {
    isCodeSent: false,
    isCodeVerified: false,
    passcode: "",
    verifiedPhoneNumber: "",
  },
  // validationSchema: CustomerInfoSchema,
  onSubmit: async (values) => {
    if (values.isCodeSent) {
      await verifyContactCode({
        variables: {
          phoneNumber: form.values.representative.phoneNumber,
          passcode: values.passcode,
        },
      }).then(({ data }) => {
        if (data?.verifyContactCode) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Phone number verified",
            })
          );
          verifyContactForm.setFieldValue("isCodeVerified", true);
          verifyContactForm.setFieldValue(
            "verifiedPhoneNumber",
            form.values.representative.phoneNumber
          );
          form.setFieldError("representative.phoneNumber", undefined);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not send verification code",
            })
          );
        }
      });
    } else {
      await sendVerifyContactCode({
        variables: {
          phoneNumber: form.values.representative.phoneNumber,
        },
      }).then(({ data }) => {
        if (data?.sendVerifyContactCode) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Verification Code Sent",
            })
          );
          verifyContactForm.setFieldValue("isCodeSent", true);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not send verification code",
            })
          );
        }
      });
    }
  },
  onReset: () => {
    handleCancel?.();
  },
});


  const [resolveGhanaCardDetails, { loading: loadingGhanaCard }] = useLazyQuery(
    RESOLVE_GHANA_CARD_INFO,
  );

  useEffect(() => {
    if (
      form.values.representative.ghanaCardNumber?.match(
        validators.GhanaCardRegex,
      )
    ) {
      resolveGhanaCardDetails({
        variables: {
          ghanaCardNumber: form.values.representative.ghanaCardNumber,
        },
      })
        .then(({ data, error }) => {
          if (data?.ghanaCard?._id) {
            if (data.ghanaCard?.fullName) {
              form.setFieldValue(
                "representative.fullName",
                data.ghanaCard.fullName,
              );
            }
            if (data.ghanaCard?.gender) {
              form.setFieldValue(
                "representative.gender",
                data.ghanaCard.gender,
              );
            }
            if (data.ghanaCard?.dateOfBirth) {
              form.setFieldValue(
                "representative.dateOfBirth",
                moment(data.ghanaCard.dateOfBirth).format("YYYY-MM-DD"),
              );
            }
            if (data.ghanaCard?.issueDate) {
              form.setFieldValue(
                "representative.ghanaCardIssueDate",
                moment(data.ghanaCard.issueDate).format("YYYY-MM-DD"),
              );
            }
            if (data.ghanaCard?.expiryDate) {
              form.setFieldValue(
                "representative.ghanaCardExpiryDate",
                moment(data.ghanaCard.expiryDate).format("YYYY-MM-DD"),
              );
            }
          } else if (error) {
            // form.setFieldTouched("representative.ghanaCardNumber", true);
            // form.setFieldError(
            //   "representative.ghanaCardNumber",
            //   error?.message
            // );
          }
        })
        .catch((err) => {
          // form.setFieldTouched("representative.ghanaCardNumber", true);
          // form.setFieldError(
          //   "representative.ghanaCardNumber",
          //   "Invalid Ghana Card Number"
          // );
        });
    } else if (form.values.representative.hasGhanaCard) {
      form.setFieldValue("representative.fullName", "");
      form.setFieldValue("representative.gender", "");
      form.setFieldValue("representative.dateOfBirth", "");
      form.setFieldValue("representative.ghanaCardIssueDate", "");
      form.setFieldValue("representative.ghanaCardExpiryDate", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.representative.ghanaCardNumber]);

  const formLabel = useMemo(
    () =>
      form.values.customerType === "Organization"
        ? "Representative"
        : "Customer",
    [form.values.customerType],
  );

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        <div>
          <span className="text-xs font-light">Customer Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div>
              <SelectInput
                id="customerType"
                label="Customer Type"
                options={[
                  { label: "--- Select Customer Type ---", value: "" },
                  {
                    label: "Organization",
                    value: "Organization",
                  },
                  { label: "Individual", value: "Individual" },
                ]}
                required={true}
                {...form}
              />
            </div>
            {form.values.customerType === "Organization" && (
              <div>
                <SelectInput
                  id="organization.type"
                  label="Organization Type"
                  options={[
                    { label: "--- Select Organization Type ---", value: "" },
                    { label: "Public", value: "Public" },
                    { label: "Private", value: "Private" },
                  ]}
                  required={form.values.customerType === "Organization"}
                  {...form}
                />
              </div>
            )}
          </div>
        </div>
        {form.values.customerType === "Organization" && (
          <div className="pt-6">
            <span className="text-xs font-light">Organization Information</span>
            <div className="grid grid-cols-3 gap-6 mt-2">
              <div className="col-span-2">
                <TextInput
                  id="organization.name"
                  label="Full Name"
                  type="text"
                  required={true}
                  placeholder="e.g. Kofi And Sons Organization Limited"
                  {...form}
                />
              </div>
            </div>
          </div>
        )}
        <div className="pt-6">
          <span className="text-xs font-light">{formLabel} Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="col-span-1">
              <Switch.Group as="div">
                <span className="flex flex-grow flex-col">
                  <Switch.Label
                    as="span"
                    className="text-sm font-medium text-gray-900"
                    passive
                  >
                    Has Ghana Card
                  </Switch.Label>
                </span>
                <div className="flex items-center justify-between mt-1  h-[38px]">
                  <Switch.Description
                    as="span"
                    className="text-sm text-gray-500"
                  >
                    Do you have a Ghana Card?
                  </Switch.Description>
                  <Switch
                    checked={form.values.representative.hasGhanaCard}
                    onChange={(val: boolean) => {
                      form.setFieldValue("representative.hasGhanaCard", val);
                      form.setFieldValue("representative.ghanaCardNumber", "");
                      form.setFieldValue("representative.fullName", "");
                      form.setFieldValue("representative.gender", "");
                      form.setFieldValue("representative.dateOfBirth", "");
                      form.setFieldValue(
                        "representative.ghanaCardIssueDate",
                        "",
                      );
                      form.setFieldValue(
                        "representative.ghanaCardExpiryDate",
                        "",
                      );
                    }}
                    className={classNames(
                      form.values.representative.hasGhanaCard
                        ? "bg-primary-600"
                        : "bg-gray-200",
                      "relative items-center inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2",
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        form.values.representative.hasGhanaCard
                          ? "translate-x-5"
                          : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                      )}
                    />
                  </Switch>
                </div>
              </Switch.Group>
            </div>
            {form.values.representative.hasGhanaCard && (
              <div className="col-span-1">
                <TextInput
                  id="representative.ghanaCardNumber"
                  label="Ghana Card Number"
                  type="text"
                  placeholder="eg. GHA-123456789-0"
                  minLength={15}
                  maxLength={15}
                  required={true}
                  {...form}
                  postText={loadingGhanaCard ? "loading..." : ""}
                />
              </div>
            )}
          </div>

          <div className="grid grid-cols-3 gap-6 border-t border-gray-200 pt-6 mt-6">
            <div className="col-start-1">
              <AvatarUpload
                id="representative.profileImageUrl"
                label="Photo"
                {...form}
              />
            </div>
            <div className="col-start-1">
              <SelectInput
                id="representative.title"
                label={`${formLabel}'s Title`}
                options={[
                  { label: "--- Select Title ---", value: "" },
                  ...Titles,
                ]}
                required={true}
                {...form}
              />
            </div>

            <div className="col-span-2">
              <TextInput
                id="representative.fullName"
                label={`${formLabel}'s Full Name`}
                type="text"
                required={true}
                placeholder="e.g. Mensah Enoch Nana Nyankah"
                // disabled={form.values.representative.hasGhanaCard}
                {...form}
              />
            </div>

            <div>
              <SelectInput
                id="representative.nationality"
                label={`${formLabel}'s Nationality`}
                options={[
                  { label: "--- Select Nationality ---", value: "" },
                  ..._
                    .map(Nationalities, "nationality")
                    .map((nationality) => ({
                      label: nationality,
                      value: nationality,
                    })),
                ]}
                required={true}
                {...form}
              />
            </div>

            <div>
              <TextInput
                id="representative.dateOfBirth"
                label={`${formLabel}'s Date of Birth`}
                type="date"
                required={true}
                max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                // disabled={
                //   form.values.representative.hasGhanaCard &&
                //   ghanaCardData?.ghanaCard?.dateOfBirth
                // }
                {...form}
              />
            </div>

            <div>
              <SelectInput
                id="representative.gender"
                label={`${formLabel}'s Gender`}
                options={[
                  { label: "--- Select Gender ---", value: "" },
                  ...Genders,
                ]}
                required={true}
                // disabled={form.values.representative.hasGhanaCard}
                {...form}
              />
            </div>
          </div>
        </div>
        <div className="pt-6">
          <span className="text-xs font-light">Contact Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div>
              <TextInput
                id="representative.phoneNumber"
                label={`${formLabel}'s Phone Number`}
                type="text"
                placeholder="e.g. 0550123292"
                maxLength={10}
                required={true}
                postText={
                  (_.get(verifyContactForm.values, "verifiedPhoneNumber") ===
                    _.get(form.values, "representative.phoneNumber")) && (
                    _.get(form.values, "representative.phoneNumber") !== ""
                  )
                    ? (
                      <CheckCircleIcon className='text-green-500 w-5 h-5'/>
                    ) : null
                }
                {...form}
              />

              {_.get(form.values, "representative.phoneNumber") &&
                (!_.get(form.errors, "representative.phoneNumber") ||
                  _.get(form.errors, "representative.phoneNumber") ===
                  "Kindly verify phone number") && (
                  <form onSubmit={verifyContactForm.handleSubmit}>
                      {!_.get(verifyContactForm.values, "isCodeVerified") && 
                      _.get(verifyContactForm.values, "isCodeSent") &&
                      (
                        <div className="flex-1 mt-4">
                          <TextInput
                            id='passcode'
                            type='text'
                            placeholder='Code'
                            minLength={6}
                            maxLength={6}
                            {...verifyContactForm}
                          />
                        </div>
                      )}
                        <div className='flex justify-end mt-4 items-center gap-x-2.5'>
                          {!_.get(verifyContactForm.values, "isCodeVerified") && (
                          <button
                            type='submit'
                            className='inline-flex justify-center h-full rounded-md bg-primary-600 py-[7px] px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
                          >
                            {_.get(verifyContactForm.values, "isCodeSent")
                              ? verifyContactLoading
                                ? "Verifying..."
                                : "Verify"
                              : sendVerifyContactLoading
                                ? "Sending..."
                                : "Send OTP"
                            }
                          </button>
                            
                          )}
                          {_.get(verifyContactForm.values, "isCodeSent") && 
                          !_.get(verifyContactForm.values, "isCodeVerified") &&
                          (
                            <button
                              type='button'
                              onClick={wrapClick(async () => {
                                await sendVerifyContactCode({
                                  variables: {
                                    phoneNumber:
                                    form.values.representative.phoneNumber,
                                  },
                                }).then(({data}) => {
                                  if (data?.sendVerifyContactCode) {
                                    toast(
                                      JSON.stringify({
                                        type: "success",
                                        title: "Verification Code Sent",
                                      })
                                    );
                                    verifyContactForm.setFieldValue(
                                      "isCodeSent",
                                      true
                                    );
                                  } else {
                                    toast(
                                      JSON.stringify({
                                        type: "error",
                                        title: "Could not send verification code",
                                      })
                                    );
                                  }
                                });
                              })}
                              className='inline-flex justify-center h-full rounded-md bg-primary-600 py-[7px] px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
                            >
                              {sendVerifyContactLoading ? "Sending..." : "Resend"}
                            </button>
                          )}
                        </div>
                  </form>
                )}
            </div>

            <div className="col-span-2">
              <TextInput
                id="representative.emailAddress"
                label={`${formLabel}'s Email Address`}
                type="email"
                placeholder="e.g. nyankahmensah@gmail.com"
                {...form}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          // disabled={!form.isValid}
          className={classNames(
            true ? "hover:bg-primary-700" : "cursor-not-allowed",
            "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm",
          )}
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
